import './chart.scss';
import { observer } from 'mobx-react-lite';
import Highcharts, { AxisSetExtremesEventObject } from 'highcharts';
import HighchartsReact, {
	HighchartsReactProps,
} from 'highcharts-react-official';
import ChartColors from '@components/core/chart/chart-colors.ts';
import highchartsBoost from 'highcharts/modules/boost';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import highchartsGantt from 'highcharts/modules/gantt';
import highchartsStock from 'highcharts/modules/stock';
import highchartsAnnotations from 'highcharts/modules/annotations';
import themeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import extendObject from '@utils/extend-object.ts';
import { useContext, useEffect, useRef } from 'react';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import copyService from '@services/copy-service.ts';
import viewStore from '@store/view-store.ts';
import anchorService from '@services/anchor-service.ts';
import ChartOutline from '@components/core/chart/chart-outline.tsx';
import getGraphId from '@utils/get-graph-id.ts';
import { PlaceStatGranularity } from '@store/place-stats-store.ts';
import useInitialScreenHeightChanged from '@hooks/use-initial-screen-height-changed.tsx';
import { TabContext } from '@components/view/view-tab-panel.tsx';
import setHashExtremes from '@components/core/chart/chart-set-hash-extremes.ts';
import { HashParamMap } from '@/enums/hash-params.ts';

highchartsBoost(Highcharts);
highchartsAccessibility(Highcharts);
highchartsGantt(Highcharts);
highchartsStock(Highcharts);
highchartsAnnotations(Highcharts);

const defaultOptions: Highcharts.Options = {
	credits: {
		enabled: false,
	},
	time: {
		useUTC: false,
	},
	tooltip: {
		outside: true,
		style: {
			zIndex: 100,
		},
	},
	chart: {
		style: {
			fontFamily: 'Inter',
		},
		zooming: {
			type: 'x',
			mouseWheel: false,
		},
		panning: {
			enabled: true,
			type: 'x',
		},
		panKey: 'shift',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		events: {
			load: function () {
				setHashExtremes(this);
			},
		},
	},
	title: {
		style: {
			fontWeight: '400',
			fontSize: '1.5rem',
		},
	},
	xAxis: {
		labels: {
			style: {
				color: themeStore.lightTheme
					? BrandColors.gray600
					: BrandColors.white,
			},
		},
	},
	rangeSelector: {
		enabled: true,
		selected: 2,
		buttons: [
			{
				type: 'day',
				count: 1,
				text: '1d',
				title: 'View 1 day',
			},
			{
				type: 'day',
				count: 7,
				text: '7d',
				title: 'View 7 days',
			},
			{
				type: 'month',
				count: 1,
				text: '1m',
				title: 'View 1 month',
			},
			{
				type: 'month',
				count: 3,
				text: '3m',
				title: 'View 3 months',
			},
			{
				type: 'month',
				count: 6,
				text: '6m',
				title: 'View 6 months',
			},
			{
				type: 'ytd',
				text: 'YTD',
				title: 'View year to date',
			},
			{
				type: 'year',
				count: 1,
				text: '1y',
				title: 'View 1 year',
			},
			{
				type: 'all',
				text: 'All',
				title: 'View all',
			},
		],
		buttonPosition: {
			x: -20,
		},
		buttonTheme: {
			r: 10,
			style: {
				borderWidth: '1px solid black',
			},
			fill: 'none',
			states: {
				hover: {
					fill: BrandColors.blue500,
					style: {
						color: BrandColors.white,
					},
				},
				select: {
					fill: BrandColors.gray600,
					style: {
						color: BrandColors.white,
					},
				},
			},
		},
		inputEnabled: true,
		inputStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
			backgroundColor: themeStore.lightTheme
				? BrandColors.white
				: BrandColors.gray500,
		},
		labelStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
		},
	},
};

Highcharts.setOptions(defaultOptions);

interface ChartProps extends HighchartsReactProps {
	granularity?: PlaceStatGranularity;
	onLoad?: (chart: Highcharts.Chart) => unknown;
	containerClassName?: string;
	disableResponsiveHeight?: boolean;
}

const Chart = observer(function Chart(props: ChartProps) {
	const xExtremes = useRef<{ min: number; max: number } | undefined>(
		undefined
	);

	const chart = useRef<Highcharts.Chart | undefined>();

	const chartOptions = {
		...props.options,
	};

	extendObject(chartOptions, ['colors'], ChartColors());

	extendObject(
		chartOptions,
		['title', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['yAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['xAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['legend', 'itemStyle', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		[
			'rangeSelector',
			'buttonTheme',
			'states',
			'disabled',
			'style',
			'color',
		],
		themeStore.lightTheme ? BrandColors.gray400 : BrandColors.gray500
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'states', 'select', 'fill'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.blue525
	);

	extendObject(
		chartOptions,
		['xAxis', 'events', 'setExtremes'],
		(extremes: AxisSetExtremesEventObject) => {
			xExtremes.current = { min: extremes.min, max: extremes.max };
		}
	);

	extendObject(chartOptions, ['chart', 'events', 'render'], (draw: Event) => {
		chart.current = draw.target as unknown as Highcharts.Chart;
	});

	if (
		props.options?.xAxis &&
		!Array.isArray(props.options?.xAxis) &&
		props.options.xAxis.type === 'datetime'
	) {
		extendObject(chartOptions, ['xAxis', 'minRange'], 86400000); // Set default min range to 1 day
	}

	const responsiveRules = {
		condition: {
			maxWidth: 480,
		},
		chartOptions: {
			legend: {
				enabled: false, // Hide the legend
			},
			navigator: {
				enabled: false,
			},
		},
	};

	if (!props.disableResponsiveHeight) {
		extendObject(responsiveRules, ['chartOptions', 'chart', 'height'], 300);
	}

	if (chartOptions.responsive?.rules) {
		chartOptions.responsive.rules.push(responsiveRules);
	} else {
		extendObject(chartOptions, ['responsive', 'rules'], [responsiveRules]);
	}

	if (chartOptions.title?.text === 'Platform Genre Distribution') {
		console.log(chartOptions.responsive?.rules);
	}

	useEffect(() => {
		if (chart.current && xExtremes.current) {
			chart.current.xAxis.forEach((axis) => {
				axis.setExtremes(
					xExtremes.current?.min,
					xExtremes.current?.max,
					true,
					false
				);
			});
		}
	}, [props.options]);

	const tab = useContext(TabContext);

	const screenHeightChanged = useInitialScreenHeightChanged();

	useEffect(() => {
		if (
			props.options?.title?.text &&
			!viewStore.loadingViewData &&
			!screenHeightChanged
		) {
			const graphId = getGraphId(props.options.title.text);
			requestAnimationFrame(() => {
				anchorService.scrollTo(graphId);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [viewStore.loadingViewData, screenHeightChanged]);

	return (
		<>
			<div
				className={ClassString({
					static: 'relative overflow-hidden',
					dynamic: {},
					custom: props.containerClassName,
				})}
				id={
					props.options?.title?.text
						? getGraphId(props.options.title.text)
						: undefined
				}
			>
				{!!props.options?.title?.text && (
					<>
						<Icon
							className={
								'absolute top-0 right-0 hover:bg-blue-500 hover:text-white p-2 pointer-events-auto rounded-md cursor-pointer z-in-front'
							}
							icon={IconEnum.SHARE}
							size={'2.5rem'}
							onClick={() => {
								if (
									props.options?.title?.text &&
									chart.current
								) {
									const extraParams: HashParamMap = {};

									const extremes =
										chart.current.xAxis[0].getExtremes();

									if (extremes.min && extremes.max) {
										extraParams.graphMin =
											extremes.min.toString();
										extraParams.graphMax =
											extremes.max.toString();
									}

									if (props.granularity) {
										extraParams.granularity =
											props.granularity;
									}

									if (tab) {
										extraParams.tab = tab;
									}

									copyService.anchorLink(
										getGraphId(props.options.title.text),
										extraParams
									);
								}
							}}
						/>
					</>
				)}

				<div
					className={
						'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden w-full h-full'
					}
				>
					<div
						className={ClassString({
							static: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-repeat -rotate-45 w-[120%] h-[300%]',
							dynamic: {
								'bg-watermark-black opacity-3':
									themeStore.lightTheme,
								'bg-watermark-white opacity-2':
									themeStore.darkTheme,
							},
						})}
					></div>
				</div>
				<HighchartsReact
					containerProps={{
						className: `highcharts-${themeStore.lightTheme ? 'light' : 'dark'}`,
					}}
					highcharts={{
						...Highcharts,
						...props.highcharts,
					}}
					options={chartOptions}
					constructorType={props.constructorType}
				/>
			</div>

			{!!props.options?.title?.text && (
				<ChartOutline
					elementId={getGraphId(props.options.title.text)}
				/>
			)}
		</>
	);
});

export default Chart;
